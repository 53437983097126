<template>
  <div class="add-form">
    <div class="alarm-item" v-loading="loading">
      <div>
        <img :src="smtp" />
        <div class="content">
          <div class="title">{{ form.email.smtp_smarthost || "-" }}</div>
          <div class="desc">{{ $t("smtpHost") }}</div>
        </div>

        <div>
          <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
            <svg-icon icon-class="edit" class-name="handle-icon" @click.native="edit('AlertKindEmail')"></svg-icon>
          </el-tooltip>
        </div>
      </div>

      <div>
        <img :src="slack" />
        <div class="content">
          <div class="title">{{ form.slack.slack_api_url || "-" }}</div>
          <div class="desc">{{ $t("slackHost") }}</div>
        </div>

        <div>
          <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
            <svg-icon icon-class="edit" class-name="handle-icon" @click.native="edit('AlertSlack')"></svg-icon>
          </el-tooltip>
        </div>
      </div>

      <div>
        <img :src="wechat" />
        <div class="content">
          <div class="title">{{ form.wechat.wechat_api_url || "-" }}</div>
          <div class="desc">{{ $t("wechatHost") }}</div>
        </div>

        <div>
          <el-tooltip effect="dark" :content="$t('handle.edit')" placement="bottom">
            <svg-icon icon-class="edit" class-name="handle-icon" @click.native="edit('AlertWechat')"></svg-icon>
          </el-tooltip>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('handle.edit')"
      :visible.sync="dialogVisible"
      top="30px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <el-form :model="form" label-position="top" size="small" ref="form">
        <div v-if="form.type == 'AlertKindEmail'">
          <el-form-item
            :label="$t('smtpHost')"
            prop="email.smtp_smarthost"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.email.smtp_smarthost"></el-input>
          </el-form-item>

          <el-form-item :label="$t('openTLS')">
            <el-switch v-model="form.email.smtp_require_tls"> </el-switch>
          </el-form-item>

          <el-form-item
            :label="$t('sender')"
            prop="email.smtp_from"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.email.smtp_from"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('smtpAuthUserName')"
            prop="email.smtp_auth_username"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.email.smtp_auth_username"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('smtpAuthPassword')"
            prop="email.smtp_auth_password"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.email.smtp_auth_password" type="password"></el-input>
          </el-form-item>
        </div>

        <div v-if="form.type == 'AlertSlack'">
          <el-form-item
            :label="$t('slackHost')"
            prop="slack.slack_api_url"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.slack.slack_api_url"></el-input>
          </el-form-item>
        </div>

        <div v-if="form.type == 'AlertWechat'">
          <el-form-item
            :label="$t('wechatHost')"
            prop="wechat.wechat_api_url"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.wechat.wechat_api_url"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('wechatApiSecret')"
            prop="wechat.wechat_api_secret"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.wechat.wechat_api_secret"></el-input>
          </el-form-item>

          <el-form-item
            :label="$t('wechatCorpId')"
            prop="wechat.wechat_api_corp_id"
            :rules="[{ required: true, message: $t('rules.require'), trigger: 'blur' }]"
          >
            <el-input v-model="form.wechat.wechat_api_corp_id"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import slack from "@/assets/provider/slack.svg";
import wechat from "@/assets/provider/wechat.svg";
import smtp from "@/assets/provider/smtp.svg";

import { addConfigs, configs } from "api/alert";

export default {
  data() {
    return {
      slack,
      wechat,
      smtp,

      dialogVisible: false,
      submitLoading: false,
      loading: true,

      form: {
        type: "AlertKindEmail",
        email: {
          smtp_from: "",
          smtp_smarthost: "",
          smtp_auth_username: "",
          smtp_auth_password: "",
          smtp_require_tls: true
        },
        slack: {
          slack_api_url: ""
        },
        wechat: {
          wechat_api_url: "",
          wechat_api_secret: "",
          wechat_api_corp_id: ""
        }
      }
    };
  },

  methods: {
    getConfigs() {
      this.loading = true;
      configs().then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.form.email.smtp_from = response.data.smtp_from || "";
          this.form.email.smtp_smarthost = response.data.smtp_smarthost || "";
          this.form.email.smtp_require_tls = response.data.smtp_require_tls || true;
          this.form.email.smtp_auth_username = response.data.smtp_auth_username || "";
          this.form.email.smtp_auth_password = response.data.smtp_auth_password || "";

          this.form.slack.slack_api_url = response.data.slack_api_url || "";

          this.form.wechat.wechat_api_url = response.data.wechat_api_url || "";
          this.form.wechat.wechat_api_secret = response.data.wechat_api_secret || "";
          this.form.wechat.wechat_api_corp_id = response.data.wechat_api_corp_id || "";
        }
      });
    },

    edit(type) {
      this.form.type = type;
      this.dialogVisible = true;
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;
          addConfigs(this.form).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.getConfigs();
              this.dialogVisible = false;
            }
          });
        }
      });
    }
  },

  mounted() {
    this.getConfigs();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.alarm-item {
  & > div {
    @include resource-card();
    font-size: 14px;
  }
}

.alert-icon {
  width: 14px;
  height: 14px;
}
</style>
