import http from "../http";



export const addConfigs = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/notification/global/configs/metrics`,
    data
  });
};

export const configs = () => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/notification/global/configs/metrics`
  });
};

export const receivers = () => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/notification/receiver/configs/metrics`
  });
};

export const deleteReceivers = name => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/notification/receiver/${name}/configs/metrics`
  });
};

export const addReceivers = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/notification/receiver/configs/metrics`,
    data
  });
};

export const routes = () => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/notification/route/configs/metrics`
  });
};

export const addRoutes = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/notification/route/configs/metrics`,
    data
  });
};

export const addAlarms = (payload, data, params) => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/applications/${payload.name}/alert/metrics`,
    data,
    params
  });
};

export const deleteAlarms = (payload, data) => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/applications/${payload.name}/alert/metrics`,
    data
  });
};

export const alarms = payload => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/applications/${payload.name}/alert/metrics`
  });
};

export const promql = (payload, params) => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/applications/${payload.name}/promql/metrics`,
    params
  });
};

export const alarmMessages = params => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/notification/alert/metrics`,
    params
  });
};

export const getSilences = () => {
  return http({
    method: "get",
    url: `/monitor.kubestar.io/notification/silences/metrics`
  });
};

export const addSilence = data => {
  return http({
    method: "post",
    url: `/monitor.kubestar.io/notification/silences/metrics`,
    data
  });
};

export const deleteSilence = id => {
  return http({
    method: "delete",
    url: `/monitor.kubestar.io/notification/silences/${id}/metrics`
  });
};
